import React, { useState, useEffect } from "react";
import { Divider, Checkbox, Modal, Form, Button } from "semantic-ui-react";
import Loadable from "react-loadable";
//import Loading from "../../util

import css from "./editor.css";

const LoadableEdit = Loadable({
  loader: () => import("../../common/components/CKEdit"),
  loading() {
    return <div>Loading...</div>;
  },

  render(loaded, props) {
    let Component = loaded.default;
    return (
      <Component
        key={props.id}
        id={props.id}
        text={props.text}
        handleChangeText={props.handleChangeText}
      />
    );
  },
});

export const TextEditor = ({ text, handleChangeText, id }) => {
  return (
    <LoadableEdit
      key={id}
      id={id}
      text={text}
      handleChangeText={handleChangeText}
    />
  );
};
export default TextEditor;
